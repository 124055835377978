import React from 'react';
import {graphql} from 'gatsby';
import loadable from '@loadable/component';
import './MembrePage.scss'
import PageHeader from "../components/PageHeader";
import Content from "../components/Content";

const Layout = loadable(() => import('../components/Layout'));

const getFullName = (name, firstName) => {
    return name + ' ' + firstName;
};


const featureImageHeader = "https://ucarecdn.com/9fb4cac4-629f-4870-9727-675d9fae61ae/";

export const MembrePageTemplate = ({
                                       title,
                                       job,
                                       name,
                                       firstname,
                                       featuredImage,
                                       body,
                                       meta,
                                       skills,
                                       diplomas
                                   }) => (
    <main className="membrePage">
        <PageHeader
            large
            title=''
            subtitle=''
            backgroundImage={featureImageHeader}
        />
        <section className="art-member-container flex flex-col md:flex-row container">
            <img src={featuredImage} className="art-membre-photo flex-none md:sticky md:top-0"/>
            <section className="art-member-description flex-auto">
                <h3 className="art-membre-title">Dr {firstname} {name}</h3>
                <article>
                    {body &&
                    <h6 className="art-member-subtitle">Présentation</h6>
                    }
                    {body && (
                        <Content className="art-member-cv" source={body}/>)}
                </article>
                <article>
                    {
                        skills &&
                        <h6 className="art-member-subtitle"><span className="art-member-icon-skills"></span>Compétences
                        </h6>
                    }

                    <ul className="art-member-list">
                        {
                            skills &&
                            skills.map((skill) => (
                                <li>
                                    {skill.link && <a href={skill.link}>{skill.name}</a>}
                                    {!skill.link && <div>{skill.name}</div>}
                                </li>
                            ))
                        }
                    </ul>

                    {
                        diplomas &&
                        <h6 className="art-member-subtitle"><span className="art-member-icon-skills"></span>Diplômes
                        </h6>
                    }

                    <ul className="art-member-list">
                        {
                            diplomas &&
                            diplomas.map((diploma) => (
                                <li>{diploma.name}</li>
                            ))
                        }
                    </ul>


                </article>
            </section>
        </section>
    </main>
);

// Export Default SinglePost for front-end
const MembrePage = ({data: {membre}}) => {
    return (
        <Layout
            meta={membre.frontmatter.meta || false}
            title={membre.frontmatter.title || false}
        >
            <MembrePageTemplate
                {...membre}
                {...membre.frontmatter}
                body={membre.html}
                meta={membre.frontmatter.meta}
            />
        </Layout>
    )
};

export default MembrePage

export const pageQuery = graphql`
  ## Query for Membre data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query MembrePage($id: String!) {
    membre: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        name
        firstname
        featuredImage
        job
        skills {
           name
           link
        }
        diplomas {
           name
        }
      }
    }
  }
`;
